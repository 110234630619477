import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import absoluteUrl from '~/utils/absoluteUrl';

interface Props {
  canonical?: string | false | null;
}

const DefaultCanonical: React.FC<Props> = ({ canonical }) => {
  const router = useRouter();

  if (canonical === false) {
    return null;
  }

  if (canonical) {
    return (
      <Head>
        <link rel="canonical" href={canonical} />
      </Head>
    );
  }

  return (
    <Head>
      <link rel="canonical" href={absoluteUrl(router.asPath)} />
    </Head>
  );
};

export default DefaultCanonical;
