'use client';

import { getEnv } from 'common-nextjs';
import { getErrorMessage } from 'fetcher-session';
import React from 'react';
import { Message } from 'ui';
import { getSentry } from '~/services/sentry/wrapper';

interface Props {
  children?: React.ReactNode;
  fallback?:
    | React.ReactNode
    | ((error: Error, errorInfo: React.ErrorInfo | null) => React.ReactNode)
    | 'auto';
  key?: any;

  sentryExtras?: Record<string, any>;
}

type NextError = Error & { digest?: string };

interface State {
  error: NextError | null;
  errorInfo: React.ErrorInfo | null;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error: NextError) {
    // Update state so the next render will show the fallback UI.
    return {
      error,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    // Reset error state when props change
    if (this.props != prevProps) {
      this.setState({
        error: null,
        errorInfo: null,
      });
    }
  }

  componentDidCatch(error: NextError, errorInfo) {
    super.componentDidCatch?.(error, errorInfo);

    console.error(error, errorInfo);

    getSentry()?.captureException(error, {
      extra: {
        ...this.props.sentryExtras,
        errorInfo,
        digest: error.digest,
      },
    });

    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.error) {
      if (this.props.fallback === 'auto') {
        if (getEnv('NODE_ENV') === 'development') {
          return (
            <Message error>
              Something went wrong: {getErrorMessage(this.state.error)}
            </Message>
          );
        } else if ('digest' in this.state.error) {
          return (
            <Message error>
              Something went wrong. (digest {String(this.state.error.digest)})
            </Message>
          );
        } else {
          return (
            <Message error>
              Something went wrong. Please try again later.
            </Message>
          );
        }
      } else if (typeof this.props.fallback === 'function') {
        return this.props.fallback(this.state.error, this.state.errorInfo);
      } else {
        return this.props.fallback;
      }
    }

    return this.props.children;
  }
}
