import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import {
  ArrowTopRightOnSquareIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { useDebounceValue } from 'common-nextjs';
import { format, isThisYear, isToday } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { IconButton, Input, Loader, Tab } from 'ui';
import { fetchBadgeCount } from '~/api/swaps';
import {
  swapsInboxCountQuery,
  swapsInboxQuery,
} from '~/api/swapsInbox/queries';
import { RailsInboxSwap, RailsSwapInboxFilter } from '~/api/swapsInbox/types';
import Image from '~/components/Image';
import SwapDetailsLink from '~/components/Links/SwapDetailsLink';
import SwapsInboxLink from '~/components/Links/SwapsInboxLink';
import SwapsInboxCountIcon from '~/components/SwapsInbox/SwapsInboxCountIcon';
import { useSession } from '~/contexts/SessionContext';
import { getSwapOtherUser } from '~/hooks/swapDetails/useBuyerOrSeller';
import useKnownCookie from '~/hooks/useKnownCookie';
import { SwapsIcon } from '~/public/static/svg/ItemActionIcons';

export default function SwapsInboxPopout({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  return (
    <FloatingDialogContainer open={open} onClose={onClose}>
      <SwapsInboxPopoutBody onClose={onClose} />
    </FloatingDialogContainer>
  );
}

function SwapsInboxPopoutBody({ onClose }: { onClose: () => void }) {
  const [search, setSearch] = useState('');
  const [lastInboxLocationCookie, setLastInboxLocationCookie] =
    useKnownCookie('sls_last_inbox');
  const [inboxState, setInboxState] = useState<{
    type: string;
    scope: string;
    filters: string[];
  }>(() => {
    if (lastInboxLocationCookie) {
      const split = lastInboxLocationCookie.split('/');
      return {
        type: split[2],
        scope: split[3],
        filters: [],
      };
    } else {
      return {
        type: 'sell',
        scope: 'all',
        filters: [],
      };
    }
  });

  const debouncedSearch = useDebounceValue(search, 200);

  const { data: swapCounts } = useQuery(
    swapsInboxCountQuery(undefined, inboxState.type),
  );
  const { data: swaps, isLoading: isLoadingSwaps } = useQuery(
    swapsInboxQuery(undefined, {
      type: inboxState.type,
      scope: inboxState.scope,
      q: debouncedSearch,
      filter: inboxState.filters,
    }),
  );
  const { data: badgeCountData } = useQuery(['badge-count'], fetchBadgeCount);

  function handleChangeType(type: string) {
    setInboxState(state => ({
      ...state,
      type,
      filters: [],
      scope: 'all',
    }));

    setLastInboxLocationCookie(`/swaps/${type}/all`);
  }

  function handleChangeScope(scope: string) {
    setInboxState(state => ({
      ...state,
      scope,
      filters: [],
    }));

    setLastInboxLocationCookie(`/swaps/${inboxState.type}/${scope}`);
  }

  function handleChangeFilter(filter: string) {
    setInboxState(state => {
      let filters = [...state.filters];
      if (filters.includes(filter)) {
        filters = filters.filter(f => f !== filter);
      } else {
        filters.push(filter);
      }

      return {
        ...state,
        filters,
      };
    });
  }

  return (
    <>
      <div
        id="title-bar"
        className="bg-turf-green-500 flex items-center rounded-t px-4 py-2 text-white"
      >
        <SwapsIcon fill="currentColor" height={24} width={24} />
        <div className="ml-2 text-lg">My Swaps</div>
        <div className="ml-4">
          {badgeCountData?.badge! > 0 && (
            <div className="bg-primary-500 rounded px-1.5 text-xs text-white">
              {badgeCountData?.badge}
            </div>
          )}
        </div>
        <div className="flex-1" />

        <SwapsInboxLink type={inboxState.type} scope={inboxState.scope}>
          <IconButton color="white">
            <ArrowTopRightOnSquareIcon />
          </IconButton>
        </SwapsInboxLink>

        <IconButton onClick={onClose} color="white">
          <XMarkIcon />
        </IconButton>
      </div>

      <div className="my-4 px-4">
        <Input
          type="search"
          name="q"
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder={`Search ${
            inboxState.type === 'sell' ? 'selling' : 'buying'
          } swaps`}
          className="rounded-full"
          leftAdornment={
            <MagnifyingGlassIcon className="text-slate-green-500 ml-3.5 h-6 w-6" />
          }
        />
      </div>

      <div className="mb-4 flex w-full">
        <Tab
          selected={inboxState.type === 'sell'}
          fluid
          className="flex items-center justify-center gap-2"
          onClick={() => handleChangeType('sell')}
        >
          {swapCounts?.sellUnread && (
            <div className="h-2 w-2 rounded-full bg-green-500" />
          )}
          <span>Sell</span>
        </Tab>

        <Tab
          selected={inboxState.type === 'buy'}
          fluid
          className="flex items-center justify-center gap-2"
          onClick={() => handleChangeType('buy')}
        >
          {swapCounts?.buyUnread && (
            <div className="h-2 w-2 rounded-full bg-green-500" />
          )}
          <span>Buy</span>
        </Tab>
      </div>

      <ScopeSelector
        type={inboxState.type}
        scope={inboxState.scope}
        onChangeScope={handleChangeScope}
      />

      {!!swaps?.meta?.filters && (
        <FilterPills
          activeFilters={inboxState.filters}
          filters={swaps.meta.filters}
          onToggleFilter={handleChangeFilter}
        />
      )}

      {swaps?.data?.length! == 0 && (
        <div className="text-slate-green-500 my-12 text-center text-lg font-semibold">
          No swaps
        </div>
      )}

      <div className="h-full divide-y overflow-y-auto overflow-x-hidden pb-32">
        {swaps?.data?.map(swap => (
          <SwapRow key={swap.id} swap={swap} onClick={onClose} />
        ))}
      </div>

      {isLoadingSwaps && <Loader loading />}
    </>
  );
}

function SwapRow({
  swap,
  onClick,
}: {
  swap: RailsInboxSwap;
  onClick?: () => void;
}) {
  const { user } = useSession();

  const formattedDate = useMemo(() => {
    if (swap.updated_at) {
      const date = new Date(swap.updated_at);

      if (isToday(date)) {
        return format(date, 'h:mm a');
      } else if (isThisYear(date)) {
        return format(date, 'MMM d');
      } else {
        return format(date, 'MMM d, yyyy');
      }
    }
  }, [swap.updated_at]);

  return (
    <SwapDetailsLink
      swapId={swap.id}
      type="button"
      onClick={onClick}
      className="flex w-80 px-2 py-4 text-sm"
    >
      <div className="w-2 flex-shrink-0">
        {/* green unread indicator */}
        {swap.unread && <div className="h-2 w-2 rounded-full bg-green-500" />}
      </div>

      <div className="ml-2 flex-shrink-0">
        <Image
          alt={swap.item.name}
          src={swap.item.primary_image?.edge_url ?? ''}
          className="h-14 w-14 rounded"
          useFastly={{
            height: 64,
            width: 64,
            crop: '1:1',
          }}
        />
      </div>

      <div className="ml-2 w-52">
        <div className="mb-1 flex justify-between">
          <span className="font-semibold">
            @{getSwapOtherUser(swap, user?.username)?.username || ''}
          </span>
          <span className="font-semibold">{formattedDate}</span>
        </div>

        <div className="truncate font-semibold">{swap.item.name}</div>
        <div className="mb-2 truncate">{swap.latest_message?.body}</div>
        <div className="text-slate-green-500">{swap.label}</div>
      </div>
    </SwapDetailsLink>
  );
}

function ScopeSelector({
  type,
  scope,
  onChangeScope,
}: {
  type: string;
  scope: string;
  onChangeScope: (scope: string) => void;
}) {
  const { data: swapCounts } = useQuery(swapsInboxCountQuery(undefined, type));
  const selectedCount = swapCounts?.counts?.find(c => c.slug === scope);

  return (
    <Listbox
      as="div"
      value={scope}
      onChange={onChangeScope}
      className="relative mb-4 flex h-10 items-center px-3 text-sm"
    >
      <ListboxButton className="flex h-10 w-full items-center justify-start gap-3 rounded border px-3 text-left text-sm">
        {selectedCount ? (
          <>
            <SwapsInboxCountIcon slug={selectedCount.slug} />
            <span className="flex-1 font-semibold">{selectedCount.name}</span>
            {selectedCount.unread! > 0 && (
              <span className="flex h-5 items-center justify-center rounded-full bg-green-500 px-2 text-xs font-semibold leading-none text-white">
                {selectedCount.unread}
              </span>
            )}
          </>
        ) : (
          'Select a folder'
        )}

        <ChevronDownIcon className="h-4 w-4" />
      </ListboxButton>

      <ListboxOptions className="absolute left-2 right-2 top-0 z-10 rounded border bg-white shadow-xl">
        {swapCounts?.counts?.map((count, i) => (
          <ListboxOption
            value={count.slug}
            key={count.slug}
            className={clsx(
              'hover:bg-grey-100 flex h-10 cursor-default items-center gap-3 px-3 text-sm transition-colors duration-100',
              scope === count.slug && 'bg-grey-200',
              count.unread! > 0 && 'font-semibold',
              {
                'rounded-t': i === 0,
                'rounded-b': i === swapCounts.counts!.length - 1,
              },
            )}
          >
            <SwapsInboxCountIcon slug={count.slug} />
            <span className="flex-1">{count.name}</span>
            {count.unread! > 0 && (
              <span className="flex h-5 items-center justify-center rounded-full bg-green-500 px-2 text-xs font-semibold leading-none text-white">
                {count.unread}
              </span>
            )}
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
}

function FilterPills({
  activeFilters,
  filters,
  onToggleFilter,
}: {
  activeFilters: string[];
  filters: RailsSwapInboxFilter[];
  onToggleFilter: (filter: string) => void;
}) {
  return (
    <div className="mx-2 mb-4 flex gap-2">
      {filters.map(filter => {
        const isActive = activeFilters.includes(filter.slug);

        return (
          <button
            key={filter.slug}
            type="button"
            onClick={() => onToggleFilter(filter.slug)}
            className={clsx('rounded-full border px-3 py-1 text-xs', {
              'bg-mint-green-200 border-green-500 text-green-500': isActive,
            })}
          >
            {filter.name}
            {filter.count != null && ` (${filter.count})`}
          </button>
        );
      })}
    </div>
  );
}

function FloatingDialogContainer({
  children,
  open,
  onClose,
}: {
  children?: React.ReactNode;
  open: boolean;
  onClose: () => void;
}) {
  return (
    <Transition show={open} as={React.Fragment}>
      <Dialog
        static
        className="fixed inset-0 print:relative"
        onClose={onClose}
        style={{
          zIndex: 1000,
        }}
      >
        <div
          className={clsx(
            'flex min-h-screen flex-col items-end justify-end px-4 py-8 text-center',
            'print:block print:min-h-0 print:p-0 print:text-left',
            // If we want to center in the panel
            // !mobile && 'pl-80',
          )}
        >
          {/* Overlay */}
          <TransitionChild
            as={DialogBackdrop}
            className="bg-true-black fixed inset-0 bg-opacity-20 transition-opacity print:hidden"
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          />

          <TransitionChild
            as={React.Fragment}
            enter="ease-out duration-300 origin-right"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200 origin-top"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
          >
            <DialogPanel
              className={clsx(
                'flex w-80 flex-1 flex-col rounded-lg bg-white text-left shadow-xl transition-all',
              )}
              style={{
                maxHeight: '90vh',
              }}
            >
              {children}
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}
