import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useDebounceValue } from 'common-nextjs';
import React from 'react';
import { qsStringify } from 'utils';
import { PredictiveFilter, fetchPredictiveSearch } from '~/api/landers';
import { RailsPredictiveSearchResult } from '~/typings/services/rails/predictiveSearch';

const availableSortFn = (a, b) => b.available_count - a.available_count;

function getPathPrefix(match: RailsPredictiveSearchResult) {
  if (match.type === 'navigation') {
    return '/nav/';
  } else {
    return '/shop/';
  }
}

export default function usePredictiveSearch(
  query = '',
  filters?: PredictiveFilter[],
): [
  RailsPredictiveSearchResult[],
  (e: React.FormEvent, query: string) => void,
] {
  const queryClient = useQueryClient();
  const debouncedQuery = useDebounceValue(query, 150);

  const modifiedQuery = debouncedQuery.trim();

  const { data: predictiveResults } = useQuery(
    ['predictive-search', modifiedQuery, filters],
    () => fetchPredictiveSearch(modifiedQuery, filters),
    {
      enabled: !!modifiedQuery && modifiedQuery.length > 2,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );

  return [
    predictiveResults?.slice?.(0, 10) || [],
    async (event, rawTypedQuery) => {
      event.preventDefault();

      const modifiedQuery = rawTypedQuery.trim();

      let actualPredictiveResults = predictiveResults;

      if (!predictiveResults) {
        try {
          actualPredictiveResults = await queryClient.fetchQuery(
            ['predictive-search', modifiedQuery, filters],
            () => fetchPredictiveSearch(modifiedQuery, filters),
            {
              retry: false,
            },
          );
        } catch (e) {}
      }

      if (!actualPredictiveResults) {
        // If there's multiple matches, go to search
        window.location.assign(
          `/search${qsStringify({
            q: modifiedQuery,
          })}`,
        );
        return;
      }

      try {
        const re = new RegExp(`^${modifiedQuery}$`, 'i');

        const directMatches = actualPredictiveResults
          .filter(r => re.test(r.name))
          .sort(availableSortFn);

        const synonymMatches = actualPredictiveResults
          .filter(r => r.synonyms && r.synonyms.some(s => re.test(s)))
          .sort(availableSortFn);

        if (directMatches.length > 0) {
          window.location.assign(
            `${getPathPrefix(directMatches[0])}${directMatches[0].path}`,
          );
        } else if (synonymMatches.length > 0) {
          window.location.assign(
            `${getPathPrefix(synonymMatches[0])}${synonymMatches[0].path}`,
          );
        } else {
          window.location.assign(`/search${qsStringify({ q: modifiedQuery })}`);
        }
      } catch (e) {
        window.location.assign(
          `/search${qsStringify({
            q: modifiedQuery,
          })}`,
        );
      }
    },
  ];
}
